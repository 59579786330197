import React from "react"
import "./Connect.css"
import icon1 from "../../images/website-optimized-images/icons/Vector1.png"
import icon2 from "../../images/website-optimized-images/icons/Vector2.png"
import icon3 from "../../images/website-optimized-images/icons/Vector3.png"
import Slide from "react-reveal/Slide"

const Connect = () => {
  return (
    <>
      <div className="container-fluid" id="connectDiv">
        <Slide bottom>
          <h5>Our mission</h5>
          <p>
            Connecting people, spaces and environment to foster Innovation and
            Creativity.
          </p>
          <div className="container">
            <div className="row" id="circlesDiv">
              <div className="col-lg">
                <div className="circle1">
                  {" "}
                  <img
                    src={icon1}
                    alt="icon"
                    // style={{ width: "30px", height: "26px", marginRight: "1px" }}
                    id="imgIcon1"
                  />
                </div>
                <h6 className="titleH6">Connect</h6>
                <p className="paragraph2">
                  People, spaces and environment to foster <br />
                  Innovation and Creativity
                </p>
              </div>
              <div className="col-lg">
                <div className="circle2">
                  {" "}
                  <img
                    src={icon2}
                    alt="icon"
                    // style={{ width: "27px", height: "27px" }}
                    id="imgIcon2"
                  />
                </div>
                <h6 className="titleH6">Measure and detect</h6>
                <p className="paragraph2">
                  Changes, behaviors and sustainability metrics
                </p>
              </div>
              <div className="col-lg">
                <div className="circle3">
                  <img
                    src={icon3}
                    alt="icon"
                    // style={{ width: "27px", height: "27px" }}
                    id="imgIcon3"
                  />
                </div>
                <h6 className="titleH6">Provide</h6>
                <p className="paragraph2">
                  Cost savings, automation and data for <br />
                  medium/long-term decisions
                </p>
              </div>
            </div>
          </div>
        </Slide>
      </div>
    </>
  )
}

export default Connect
