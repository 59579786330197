import React from "react"
import "./VisionsSection.css"
import visionsImage from "../../images/website-optimized-images/vision.png"
import { IoMdCheckmarkCircle } from "react-icons/io/"
import { Accordion } from "react-bootstrap"
import Fade from "react-reveal/Fade"

const VisionSection = () => {
  return (
    <div className="container" id="visions-container">
      <Fade bottom>
        <div className="row" id="rowVisions">
          <div className="col-lg order-2 order-lg-1" id="visionsImageDiv">
            <img
              src={visionsImage}
              className="visionsImage"
              alt="ufind-visionsImage"
            />
          </div>
          <div className="col-lg order-1 order-lg-2" id="visionsTextArea">
            <h5>U-find vision</h5>
            <h1>What U-find can do for you</h1>
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  For facilities / Healthy Buildings
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    <IoMdCheckmarkCircle className="checkIcon2" />
                    Cost efficiency
                  </p>
                  <ul>
                    <li>Optimization / utilization </li>
                    <li>Energy Savings </li>
                  </ul>
                  <p>
                    <IoMdCheckmarkCircle className="checkIcon2" />
                    Environment analysis and integrated sensors
                  </p>
                  <p>
                    <IoMdCheckmarkCircle className="checkIcon2" />
                    RealTime people and devices tracking
                  </p>
                  <p>
                    <IoMdCheckmarkCircle className="checkIcon2" />
                    Space management flexibility and efficiency
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>For People</Accordion.Header>
                <Accordion.Body>
                  <p>
                    <IoMdCheckmarkCircle className="checkIcon2" />
                    Easy and timely Communication
                  </p>
                  <p>
                    <IoMdCheckmarkCircle className="checkIcon2" />
                    People Management and planning
                  </p>
                  <p>
                    <IoMdCheckmarkCircle className="checkIcon2" />
                    Visibility & Control of spaces and resources
                  </p>
                  <p>
                    <IoMdCheckmarkCircle className="checkIcon2" />
                    Managing of hybrid work environments
                  </p>
                  <p>
                    <IoMdCheckmarkCircle className="checkIcon2" />
                    Culture consistency
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Based on</Accordion.Header>
                <Accordion.Body>
                  <p>
                    <IoMdCheckmarkCircle className="checkIcon2" />
                    Scalable solution
                  </p>
                  <p>
                    <IoMdCheckmarkCircle className="checkIcon2" />
                    Web and mobile technologies
                  </p>
                  <p>
                    <IoMdCheckmarkCircle className="checkIcon2" />
                    RTLS Sensoring (WiFi, BLE)
                  </p>
                  <p>
                    <IoMdCheckmarkCircle className="checkIcon2" />
                    Intuitive and customized Map
                  </p>
                  <p>
                    <IoMdCheckmarkCircle className="checkIcon2" />
                    Advanced analytics capabilities
                  </p>
                  <p>
                    <IoMdCheckmarkCircle className="checkIcon2" />
                    Customized Views (businesses and stakeholders)
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default VisionSection
