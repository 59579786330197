import React from "react"
import Platform from "../../images/website-optimized-images/platform.png"
import "./Techonology.css"
import Fade from "react-reveal/Fade"

const Technology = () => {
  return (
    <>
      <div className="container-fluid" id="technology-container">
        <Fade bottom>
          <div className="row">
            <div className="col-lg order-2 order-lg-1" id="technologyImageDiv">
              <img
                src={Platform}
                className="technologyImage"
                alt="ufind-technologyImage"
              />
            </div>
            <div className="col-lg order-1 order-lg-2" id="technologyTextArea">
              <h5>Technology</h5>
              <h1>U-find platform</h1>
              <div className="technologyList1">
                <ul>
                  <li className="tecnologyLi">
                    <b>Web based Software </b> as a Service Cloud platform{" "}
                  </li>
                  <li className="tecnologyLi">
                    Built on <b>Open Standards</b>{" "}
                  </li>
                  <li className="tecnologyLi"> RESTful API </li>
                  <li className="tecnologyLi">
                    Cloud messaging queueing technologies for real time
                    messaging
                  </li>
                  <li className="tecnologyLi">
                    API <b>Integration capability</b>{" "}
                  </li>
                  <li className="tecnologyLi">
                    IoT sensors - extended partner ecosystem and internal
                    solution development
                  </li>
                  <li className="tecnologyLi">
                    <b>Unified approach</b> - one network to connect people and
                    devices
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default Technology
