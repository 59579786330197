import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import "./HeroSection.css"
import heroSectionImage1 from "../../images/website-optimized-images/main-image2.png"
import { Button } from "react-bootstrap"
import Fade from "react-reveal/Fade"

function HeroSection() {
  return (
    <div className="container" id="hero-container">
      <Fade bottom>
        <div className="row">
          <div className="col-lg-12" id="textArea">
            <h5>Our mission</h5>
            <h1>
              To inspire and assist people <br /> to find what they need
            </h1>
            <p>
              Next generation spaces (offices, retail shops, shopping malls,
              <br /> public areas) require a better and closer engagement with
              people. <br />
              U-find blends people, spaces and environment creating a living{" "}
              <br />
              entity that constantly changes and evolves, improves people's
              lives <br />
              and well-being, foster valuable personal and business <br />
              relationships, while driving business results.
            </p>
            <div className="hero-btns">
              <Button
                className="workspaceButton"
                variant="primary"
                as={AnchorLink}
                to="/office"
              >
                U-find Workspace
              </Button>
              <Button
                className="shopButton"
                variant="primary"
                as={AnchorLink}
                to="/shop"
              >
                U-find shop
              </Button>
            </div>

            <div className="heroImageDiv">
              <img
                src={heroSectionImage1}
                className="heroImage"
                alt="ufind-map"
              />
            </div>
          </div>
        </div>
      </Fade>

      {/* </div> */}
    </div>
  )
}

export default HeroSection
