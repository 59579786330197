import React from "react"
import "./MappingTool.css"
import MappingTools from "../../images/website-optimized-images/mapping.png"
import rectangle from "../../images/website-optimized-images/rectangle 3 .png"
import analytics from "../../images/website-optimized-images/advanced-analytics.png"
import wifi from "../../images/website-optimized-images/wifi_user-service.png"
import Fade from "react-reveal/Fade"

const MappingTool = () => {
  return (
    <>
      <div className="container" id="mapping-container">
        <Fade bottom>
          <div className="row">
            <div className="col-lg order-2 order-lg-1" id="mappingImageDiv">
              <img
                src={MappingTools}
                className="mappingImage"
                alt="ufind-mappingImage"
              />
            </div>
            <div className="col-lg order-1 order-lg-2" id="mappingTextArea">
              <h5>Live map of people and resources</h5>
              <h1>Mapping Tool</h1>
              <div className="mappingList1">
                <h5> Design Map Tool</h5>
                <ul>
                  <li className="mappingLi">
                    User defined with areas, POIs, resources and paths
                  </li>
                </ul>
                <div className="mappingList2">
                  <h5>Way finding & Buddies</h5>
                  <ul>
                    <li className="mappingLi">
                      {" "}
                      How to get to a specific place or resource
                    </li>
                    <li className="mappingLi"> Where is my friend/buddy?</li>
                    <li className="mappingLi"> Where is my car?</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="row">
            <div className="col-lg" id="locationTextArea1">
              <h1>Location and Occupancy</h1>
              <div className="locationList1">
                <h5> Notifications:</h5>
                <ul>
                  <li>
                    Real time notifications, alerts based on occupancy and{" "}
                    <br />
                    triggered alarms
                  </li>
                </ul>
                <div className="locationList2">
                  <h5>Location and Occupancy:</h5>
                  <ul>
                    <li>Real time space occupancy</li>
                    <li> Visual Map occupancy information </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg" id="locationImageDiv">
              <img
                src={rectangle}
                className="locationImage"
                alt="ufind-locationImage"
              />
            </div>
          </div>
          {/*  */}

          <div className="row" id="rowAdvancedWifi">
            <div
              className="col-lg order-2 order-lg-1"
              id="advancedAnalyticsImageDiv"
            >
              <img
                src={analytics}
                className="advancedAnalyticsImage"
                alt="ufind-mappingImage"
              />
            </div>
            <div className="col-lg order-1 order-lg-2" id="mappingTextArea">
              {/* <h5>Live map of people and resources</h5> */}
              <h1>Advanced analytics</h1>
              <div className="mappingList1">
                {/* <h5> Design Map Tool</h5> */}
                {/* <ul>
                  <li className="mappingLi">
                    User defined with areas, POIs, resources and paths
                  </li>
                </ul> */}
                <div className="mappingList2">
                  {/* <h5>Way finding & Buddies</h5> */}
                  <ul>
                    <li className="mappingLi">
                      {" "}
                      People Analytics per day of the week and location
                    </li>
                    <li className="mappingLi">
                      {" "}
                      Group/department overall information and company benchmark
                    </li>
                    <li className="mappingLi">
                      {" "}
                      Specific IT detailed analysis and quality of service
                    </li>
                    <li className="mappingLi">
                      {" "}
                      Detailed resources usage analysis
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="row">
            <div className="col-lg" id="locationTextArea1">
              <h1>WiFi and user service analysis</h1>
              <div className="wifiList1">
                {/* <h5> Notifications:</h5> */}
                <ul>
                  <li>WiFi Location accuracy analysis per area/floor</li>
                  <li>Signal quality analysis and metrics</li>
                  <li>Before and after analysis</li>
                  <li>
                    Alarms and notifications based on specific service
                    characteristics
                  </li>
                  <li>Network and device perspective analysis</li>
                </ul>
                {/* <div className="locationList2">
                  <h5>Location and Occupancy:</h5>
                  <ul>
                    <li>Real time space occupancy</li>
                    <li> Visual Map occupancy information </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-lg" id="wifiImageDiv">
              <img src={wifi} className="wifiImage" alt="ufind-locationImage" />
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default MappingTool
