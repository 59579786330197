import * as React from "react"
import "bootstrap/dist/css/bootstrap.min.css"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroSection from "../components/HeroSection/HeroSection"
import Integrations from "../components/Integrations/Integrations"
import VisionSection from "../components/VisionSection/VisionSection"
import Connect from "../components/Connect/Connect"
import Solution from "../components/Solution/Solution"
import MappingTool from "../components/MappingTool/MappingTool"
import Technology from "../components/Technology/Technology"
import AboutUs from "../components/AboutUs/AboutUs"
import RequestDemo from "../components/RequestDemo/RequestDemo"
import Scroll from "../components/ScrollToTop/Scroll"

const IndexPage = () => (
  <Layout>
    <Scroll showBelow={250} />
    <Seo title="Home" />
    <HeroSection />
    <Integrations />
    <VisionSection />
    <Connect />
    {/* <Solution /> */}
    <MappingTool />
    <Technology />
    <AboutUs />
    <RequestDemo />
    {/* <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
  </Layout>
)

export default IndexPage
