import React from "react"
import "./AboutUs.css"
import { Carousel } from "react-bootstrap"
import Avatar from "@mui/material/Avatar"
import Rui from "../../images/fotosOptimized2/Rui 2-min (1) (2).jpg"
// import Pedro from "../../images/pedro.png"
import Diogo from "../../images/fotosOptimized2/Diogo-2--min-(2).jpg"
import Miguel from "../../images/fotosOptimized2/Miguel .jpg"
import Monica from "../../images/fotosOptimized2/Mónica (1).png"
import David from "../../images/fotosOptimized2/DavidTenente-min (1).jpg"
import Fade from "react-reveal/Fade"

const AboutUs = () => {
  return (
    <>
      <div className="container" id="aboutUsSection">
        <Fade bottom>
          <div className="aboutDiv">
            <h5>About us</h5>
            <p>
              Our team is young and eager to tackle any customer problem or
              requirement. <br />
              We have a "Uman" touch approach to all problems and we use
              technology to find the best solution.
            </p>
            <div className="container" id="carrouselContainer">
              <Carousel>
                <Carousel.Item>
                  <div className="container">
                    <div className="row" id="colOneRow">
                      <div className="col-lg-3" id="colImage1">
                        {" "}
                        <div className="foto1">
                          <Avatar
                            alt="Rui"
                            src={Rui}
                            sx={{ width: 200, height: 200 }}
                            className="avatar"
                          />
                        </div>
                        <div className="captionDiv">
                          <h3>Rui Brás</h3>
                          <p>Founder, CEO/CTO</p>
                        </div>
                      </div>
                      <div className="col-lg-3" id="colImage2">
                        {" "}
                        <div className="foto1">
                          <Avatar
                            alt="Diogo"
                            src={Diogo}
                            sx={{ width: 200, height: 200 }}
                            className="avatar"
                          />
                        </div>
                        <div className="captionDiv">
                          <h3>Diogo Magalhães</h3>
                          <p>FrontEnd & Mobile Developer</p>
                        </div>
                      </div>
                      <div className="col-lg-3" id="colImage3">
                        {" "}
                        <div className="foto1">
                          <Avatar
                            alt="Miguel"
                            src={Miguel}
                            sx={{ width: 200, height: 200 }}
                            className="avatar"
                          />
                        </div>
                        <div className="captionDiv">
                          <h3>Miguel Tomé</h3>
                          <p>FullStack Developer</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="container">
                    <div className="row" id="colOneRow">
                      <div className="col-lg-3" id="colImage4">
                        {" "}
                        <div className="foto1">
                          <Avatar
                            alt="Monica"
                            src={Monica}
                            sx={{ width: 200, height: 200 }}
                            className="avatar"
                          />
                        </div>
                        <div className="captionDiv">
                          <h3>Mónica Moura</h3>
                          <p>Creative producer & Social Manager</p>
                        </div>
                      </div>
                      <div className="col-lg-3" id="colImage5">
                        {" "}
                        <div className="foto1">
                          <Avatar
                            alt="David"
                            src={David}
                            sx={{ width: 200, height: 200 }}
                            className="avatar"
                          />
                        </div>
                        <div className="captionDiv">
                          <h3>David Tenente</h3>
                          <p>Mobile Developer intern</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>

                {/* <Carousel.Item>
                  <div className="foto1">
                    <Avatar
                      alt="team photo"
                      src={Pedro}
                      sx={{ width: 200, height: 200 }}
                      className="avatar"
                    />
                  </div>
                  <Carousel.Caption>
                    <h3>Pedro Frazão</h3>
                    <p>Sales & Marketing</p>
                  </Carousel.Caption>
                </Carousel.Item> */}

                {/* <Carousel.Item>
                  <div className="foto1">
                    <Avatar
                      alt="team photo"
                      src={Diogo}
                      sx={{ width: 200, height: 200 }}
                      className="avatar"
                    />
                  </div>
                  <Carousel.Caption>
                    <h3>Diogo Magalhães</h3>
                    <p>FrontEnd & Mobile Developer</p>
                  </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                  <div className="foto1">
                    <Avatar
                      alt="team photo"
                      src={Miguel}
                      sx={{ width: 200, height: 200 }}
                      className="avatar"
                    />
                  </div>
                  <Carousel.Caption>
                    <h3>Miguel Tomé</h3>
                    <p>FrontEnd Developer</p>
                  </Carousel.Caption>
                </Carousel.Item> */}

                {/* <Carousel.Item>
                  <div className="foto1">
                    <Avatar
                      alt="team photo"
                      src={Rita}
                      sx={{ width: 200, height: 200 }}
                      className="avatar"
                    />
                  </div>
                  <Carousel.Caption>
                    <h3>Rita Briosa</h3>
                    <p>FrontEnd Developer</p>
                  </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                  <div className="foto1">
                    <Avatar
                      alt="team photo"
                      src={David}
                      sx={{ width: 200, height: 200 }}
                      className="avatar"
                    />
                  </div>
                  <Carousel.Caption>
                    <h3>David Tenente</h3>
                    <p>Mobile Developer intern</p>
                  </Carousel.Caption>
                </Carousel.Item> */}
              </Carousel>
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default AboutUs
