import React from "react"
import "./Integrations.css"
import cisco from "../../images/website-optimized-images/cisco.png"
import google from "../../images/website-optimized-images/google.png"
import office from "../../images/website-optimized-images/office.png"
import jamF from "../../images/website-optimized-images/jamF.png"
import jumpCloud from "../../images/website-optimized-images/jumpCloud.png"
import slack from "../../images/website-optimized-images/slack.png"
import Fade from "react-reveal/Fade"

const Integrations = () => {
  return (
    <>
      <div className="col-sm" id="greyStripe">
        {/* <div className="col-sm"> */}
        <Fade bottom>
          <h5 className="integrationsH5">U-find integrations:</h5>
          <div className="container">
            <div className="row align-items-center" id="integrationsImageDiv">
              <div className="col-sm">
                {" "}
                <img
                  src={cisco}
                  alt="integrations"
                  className="imageIntegrations"
                  style={{ width: "108px", height: "auto" }}
                  id="ciscoIcon"
                />
              </div>
              <div className="col-sm">
                {" "}
                <img
                  src={google}
                  alt="integrations"
                  className="imageIntegrations"
                  style={{ width: "130px", height: "auto" }}
                />
              </div>
              <div className="col-sm">
                {" "}
                <img
                  src={office}
                  alt="integrations"
                  className="imageIntegrations"
                  style={{ width: "100px", height: "auto" }}
                />
              </div>
              <div className="col-sm">
                {" "}
                <img
                  src={jamF}
                  alt="integrations"
                  className="imageIntegrations"
                  style={{ width: "65px", height: "auto" }}
                />
              </div>
              <div className="col-sm">
                {" "}
                <img
                  src={jumpCloud}
                  alt="integrations"
                  className="imageIntegrations"
                  style={{ width: "120px", height: "auto" }}
                />
              </div>
              <div className="col-sm">
                {" "}
                <img
                  src={slack}
                  alt="integrations"
                  className="imageIntegrations"
                  style={{ width: "70px", height: "auto" }}
                />
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default Integrations
